/*
 * @Author: 蒋威
 * @Date: 2022-04-07 15:52:50
 * @LastEditors: chenpeng
 * @LastEditTime: 2022-05-19 15:09:14
 * @Description:
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

import newsInfo from '@/views/newsInfo/index.vue' //新闻详情
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/homePage/index.vue'), //   columnName: '详情',
    meta: {
      title: '太阳慈善助学基金',
    },
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news/index.vue'), //   columnName: '详情',
    meta: {
      title: '新闻动态',
    },
  },
  {
    path: '/newsList',
    name: 'newsList',
    component: () => import('@/views/newsList/index.vue'), //   columnName: '详情',
    meta: {
      title: '新闻列表',
    },
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('@/views/activity/index.vue'), //   columnName: '详情',
    meta: {
      title: '公益活动',
    },
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('@/views/project/index.vue'), //   columnName: '详情',
    meta: {
      title: '公益项目',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/index.vue'), //   columnName: '详情',
    meta: {
      title: '关于我们',
    },
  },
  {
    path: '/publicity',
    name: 'publicity',
    component: () => import('@/views/publicity/index.vue'), //   columnName: '详情',
    meta: {
      title: '信息公示',
    },
  },
  {
    path: '/partyBuilding',
    name: 'partyBuilding',
    component: () => import('@/views/partyBuilding/index.vue'), //   columnName: '详情',
    meta: {
      title: '党建动态',
    },
  },
  {
    path: '/newsInfo',
    name: 'newsInfo',
    component: newsInfo, //   columnName: '详情',
    meta: {
      title: '新闻详情',
    },
  },
  // {
  //   path: '/article/:id',
  //   name: 'article',
  //   menuUrl: '/article/:id',
  //   columnName: '详情',
  //   meta: {
  //     title: '详情',
  //   },
  //   component: () => import('@/views/article/article.vue'),
  // },
  {
    path: '/404',
    name: '404',
    menuUrl: '404',
    columnName: '404',
    meta: {
      title: '404',
    },
    component: () => import('@/views/errorPage/404.vue'),
  },
  {
    path: '*',
    redirect: '/',
    meta: {
      title: '太阳慈善助学基金',
    },
  },
]
export const constantRoutes = [
  {
    path: '*',
    redirect: '/',
    meta: {
      title: '首页',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0 }),
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}
export default router
