<!--
 * @Author: 蒋威
 * @Date: 2022-04-07 08:31:56
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-14 14:03:14
 * @Description: 
-->
<template>
  <footer class="layout-footer">
    <ul class="webinfo-wrap response">
      <li class="weblogo">
        <img v-lazy="website.logo_id" alt="太阳慈善基金会" />
      </li>
      <li class="info-box">
        <label>联系电话</label>
        <div class="info-text">{{ website.phone }}</div>
      </li>
      <li class="info-box">
        <label>地址</label>
        <div class="info-text">
          {{
            website.province_name +
              website.city_name +
              website.area_name +
              website.address || ''
          }}
        </div>
      </li>
      <li class="WeChatimg">
        <img v-lazy="website.qr_code_id" alt="" />
      </li>
    </ul>
    <div class="foot-bottom">
      <div class="b-box response">
        <div class="b-left">
          <p>
            @2018 太阳慈善助学基金会 版权所有
            <a
            class="Jump" 
              data-v-6cc3389a=""
              href="http://beian.miit.gov.cn/"
              target="_blank"
            >
              {{ website.icp_no }}
            </a>
          </p>
        </div>
        <div class="b-right">
          <span>
            技术支持:
            <a class="Jump" href="http://www.puyiyun.com/" target="_blank">普益云科技</a>
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { website_list } from '@/api/homePage/index.js'

export default {
  name: 'LayoutFooter',
  data() {
    return {
      //网站配置
      website: {},
    }
  },
  methods: {
    // 获取网站配置信息
    async getwebsite_list() {
      const res = await website_list()
      if (res.code === this.$httpCode) {
        this.website = res.data
        console.log(this.website, 'sss')
      }
    },
  },
  computed: {},
  created() {
    this.getwebsite_list()
  },
}
</script>
<style lang="scss" scoped>
.layout-footer {
  height: 259px;
  background: #373d41;
  .webinfo-wrap {
    height: 209px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .weblogo {
      width: 284px;
      height: 68px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info-box {
      label {
        line-height: 16px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #d0d0d0;
      }
      .info-text {
        margin-top: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #d0d0d0;
        line-height: 16px;
      }
    }
    .WeChatimg {
      width: 109px;
      height: 109px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .foot-bottom {
    height: 50px;
    border-top: 1px solid #777777;
    .b-box {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      color: #777777;
      span,
      p,
      a {
        color: #777777;
        font-size: 14px;
        font-family: Microsoft YaHei-Light, Microsoft YaHei;
        font-weight: 300;
        color: #777777;
        line-height: 16px;
      }
      .Jump:hover{
        color: #3861d5;
      }
    }
  }
}
</style>
