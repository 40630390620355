<!--
 * @Author: 蒋威
 * @Date: 2022-04-07 08:42:40
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-20 09:49:03
 * @Description: 
-->
<template>
  <section class="app-main">
    <PageErr v-if="type == '404'" />
    <div v-else>
      <LayoutHeader v-show="$route.path != '/404'" @setType="setType" />
      <div class="layout-main">
        <router-view :key="key" v-if="isRouterAlice" />
      </div>
      <LayoutFooter v-show="$route.path != '/404'" class="" />
    </div>
  </section>
</template>

<script>
import LayoutFooter from './components/LayoutFooter/LayoutFooter.vue'
import LayoutHeader from './components/LayoutHeader/LayoutHeader.vue'
import PageErr from '@/components/404.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Layout',
  provide() {
    return {
      reload: this.reload,
    }
  },
  components: {
    LayoutFooter,
    LayoutHeader,
    PageErr,
  },
  computed: {
    key() {
      return this.$route.path
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.getScroll)
    })
  },
  data() {
    return {
      loading: true,
      isRouterAlice: true,
      type: 0,
      scrollTop: 0,
    }
  },
  methods: {
    getScroll() {
      // 滚动距离的兼容性写法
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      const bodyEle = document.body
      console.log(5888)
      if (this.scrollTop >= 118) {
        const menuBox = document.getElementById('menuBox')
        if (menuBox) {
          const clientHeight = menuBox.clientHeight
          menuBox.classList.add('fixedBox')
          bodyEle.style.marginTop = `${clientHeight}px`
        }
      } else {
        menuBox.classList.remove('fixedBox')
        bodyEle.style.marginTop = `${0}px`
      }
    },
    reload() {
      this.isRouterAlice = false
      this.$nextTick(function () {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        this.isRouterAlice = true
      })
    },
    setType(type) {
      this.type = type
    },
  },
  created() {},
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        this.type = this.$route.params.type || ''
        this.$nextTick(() => {
          const minHeight =
            document.body.clientHeight -
            document.getElementsByClassName('layout-header')[0].clientHeight -
            document.getElementsByClassName('layout-footer')[0].clientHeight
          document.getElementsByClassName('layout-main')[0]
            ? (document.getElementsByClassName(
                'layout-main'
              )[0].style.minHeight = minHeight + 'px')
            : ''
        })
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.app-main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  // overflow-x: hidden;
  // background-color: #333;
}
.layout-main {
  background: #fff;
}
.app-main.load {
  background: #fff;
  .layout-footer {
    background-color: #fff;
  }
}
</style>
