<!--
 * @Author: 蒋威
 * @Date: 2022-05-08 15:00:52
 * @LastEditors: chenpeng
 * @LastEditTime: 2022-05-20 20:35:24
 * @Description: 推荐新闻
-->
<template>
  <div class="recommend-wrap" v-if="list.length > 0">
    <div class="top-wrp">
      <h2 class="top-title pointer" @click="toNews">推荐新闻</h2>
      <i class="pointer iconfont icon-jiantou" @click="toNews"></i>
    </div>
    <div class="conetnt-wrap">
      <template v-for="(item, index) in list">
        <div
          class="conetnt-item pointer"
          :key="index"
          @click="toNewsInfo(item)"
          v-if="index < 5"
        >
          <span class="conetnt-item-time">
            {{ $getDate(item.created_at, 'MD') }}
          </span>
          <h4 class="conetnt-item-text ellipsis">
            {{ item.title }}
          </h4>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { channelTree } from '@/api/menu/index.js'
export default {
  inject: ['reload'],
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    menuList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      newList: [],
      modelId: null,
    }
  },
  methods: {
    getModuleId() {
      for (let index = 0; index < this.menuList.length; index++) {
        if (
          this.menuList[index].model == 'news' &&
          this.menuList[index].title.indexOf('新闻') != -1
        ) {
          this.moduleId = this.menuList[index].id
          break
        }
      }
      if (this.moduleId === null) {
        for (let index = 0; index < this.menuList.length; index++) {
          if (this.menuList[index].model == 'news') {
            this.moduleId = this.menuList[index].id
            break
          }
        }
      }
    },
    toNews() {
      // const routeData = this.$router.resolve({
      //   name: 'newsList'
      // })
      // window.open(routeData.href, '_blank')
      if (this.menuList.length > 0) {
        this.getModuleId()
        this.reload()
        this.$router.push({
          name: 'news',
          query: { moduleId: this.moduleId || 55 },
        })
      } else {
        this.getMenuList().then((res) => {
          this.reload()
          this.$router.push({
            name: 'news',
            query: { moduleId: this.moduleId || 55 },
          })
        })
      }
    },
    toNewsInfo(item) {
      const moduleId = this.$route.query.moduleId
      let query = {}
      if (moduleId) {
        query = { moduleId: moduleId, id: item.id }
      } else {
        query = { id: item.id }
      }
      this.reload()
      this.$router.push({
        name: 'newsInfo',
        query: query,
      })
    },
    async getMenuList() {
      const res = await channelTree({ diff: 'web' })
      if (res.code === this.$httpCode) {
        for (let index = 0; index < res.data.length; index++) {
          if (
            res.data[index].model == 'news' &&
            res.data[index].title.indexOf('新闻') != -1
          ) {
            this.moduleId = res.data[index].id
            break
          }
        }
        if (this.moduleId === null) {
          for (let index = 0; index < res.data.length; index++) {
            if (res.data[index].model == 'news') {
              this.moduleId = res.data[index].id
              break
            }
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.recommend-wrap {
  width: 290px;
  margin-bottom: 40px;
  .top-wrp {
    display: flex;
    height: 26px;
    position: relative;
    padding-left: 15px;
    align-items: center;
    justify-content: space-between;
    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      height: 26px;
      width: 4px;
      background: #e3a63b;
    }
    i {
      font-size: 18px;
      color: #cecfce;
    }
  }
  .conetnt-wrap {
    padding: 10px 0;
    .conetnt-item {
      display: flex;
      margin-top: 20px;
      span {
        height: 18px;
        flex: 0 0 40px;
        width: 40px;
        margin-right: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #a09c9c;
        line-height: 16px;
      }
      h4 {
        height: 18px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #5b5a5a;
        line-height: 16px;
      }
    }
  }
}
</style>