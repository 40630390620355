/*
 * @Author: 蒋威
 * @Date: 2022-05-08 15:47:33
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-08 15:52:22
 * @Description: 菜单相关数据
 */
const state = {
  //二级菜单
  secondmenu: [],
}

const mutations = {
  SET_SECONDMENU: (state, secondmenu) => {
    state.secondmenu = secondmenu
  },
}

const actions = {
  setsecondmenu({ commit }, secondmenu) {
    return new Promise((resolve) => {
      commit('SET_SECONDMENU', secondmenu)
      resolve(secondmenu)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
