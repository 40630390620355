/*
 * @Author: 蒋威
 * @Date: 2022-04-15 09:34:40
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-09 16:34:54
 * @Description:
 */
const getters = {
  secondmenu: (state) => state.menu.secondmenu,
}
export default getters
