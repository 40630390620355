<!--
 * @Author: 蒋威
 * @Date: 2022-05-07 13:39:02
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-08-05 08:59:11
 * @Description: 新闻动态
-->

<template>
  <div class="homepage-wrap">
    <!-- 二级菜单 -->
    <!-- <NavBox @changeMenu="changeMenu" :menuList="menuList" /> -->
    <Banner url="" :bgImg="require('@/assets/img/banner/Frame 3.png')"></Banner>
    <!-- 新闻列表 -->
    <div class="response news-container">
      <div
        class="activity-left"
        :class="
          newsList.length < 1 && activityWebList.length < 1 ? 'norightBox' : ''
        "
      >
        <template v-if="!noArt">
          <h3>{{ article.title }}</h3>
          <div
            class="article-content"
            v-html="$getHtml(article.content)"
          ></div>
        </template>
        <div class="noData" v-if="noArt">
          <div class="noart-tip">
            <img
              class="pic-404__parent"
              src="@/assets/404_images/IEVersion.jpg"
              alt="404"
            />
            <div class="IEVersion-text">文章已关闭</div>
          </div>
        </div>
      </div>
      <div
        class="activity-right"
        v-if="newsList.length > 0 || activityWebList.length > 0"
      >
        <!-- 推荐新闻 -->
        <RecommendNews
          :menuList="menuTree"
          :list="newsList"
          v-if="newsList.length > 0"
        />
        <!-- 推荐活动 -->
        <RecommendActivity
          :menuList="menuTree"
          :list="activityWebList"
          v-if="activityWebList.length > 0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { detailsByNewsId } from '@/api/news/index.js'
import RecommendNews from '@/components/RecommendNews.vue'
import RecommendActivity from '@/components/RecommendActivity.vue'

import { channelTree } from '@/api/menu/index.js'
export default {
  name: 'news',
  components: { RecommendNews, RecommendActivity },
  data() {
    return {
      // 一级菜单数据
      menuTree: [],
      // 二级菜单数据
      menuList: [],
      article: {
        title: '',
        content: '',
      },
      // 推荐新闻
      newsList: [],
      // 推荐活动
      activityWebList: [],
      // 没有文章
      noArt: false,
    }
  },
  methods: {
    getContent(data = '') {
      if (!!data) {
        let html = data.replace(
          /\<img style=\"/gi,
          '<img style="max-width:100%;height:auto;overflow: hidden;'
        )
        html =  html.replace(
          /\<img src=/gi,
          '<img style="max-width:100%;height:auto;overflow: hidden;" src='
        )
        html = html.replace(/\<p style="/gi, '<p style="word-break: break-all ')
        html = html.replace(/\<p/gi, '<p style="word-break: break-all;"')
        return html
      } else {
        return ''
      }
    },
    /**
     * @Auth: 蒋威
     * @Date: 2022-05-09 10:31:35
     * @Description: 二级菜单选中切换
     */
    changeMenu(item) {
      console.log(item, 222)
    },
    async detailsByNewsId() {
      const res = await detailsByNewsId({ newsId: this.$route.query.id })
      if (res.code === this.$httpCode) {
        this.article = res.data.newsDetails || {}
        this.noArt = !this.article || this.article == {}
        this.newsList = res.data.newsList || []
        this.activityWebList = res.data.activityWebList || []
      } else {
        this.article = {
          title: '',
          content: '',
        }
        this.newsList = []
        this.noArt = true
        this.activityWebList = []
      }
      // console.log(res, 456789)
      // console.log(456789, this.$route.query.id)
    },
    async getMenuList() {
      const res = await channelTree({ diff: 'web' })
      if (res.code === this.$httpCode) {
        this.menuTree = res.data
      }
    },
  },
  created() {
    this.detailsByNewsId()
    this.getMenuList()
  },
}
</script>

<style lang="scss" scoped>
.homepage-wrap {
  width: 100%;
  .news-container {
    padding: 40px 0px;
    display: flex;
    justify-content: space-between;
    .activity-left {
      width: 860px;
      flex: 0 0 860px;
      &.norightBox {
        width: 100%;
        flex: 0 0 100%;
      }
      h3 {
        height: 26px;
        font-size: 20px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #242121;
        line-height: 26px;
      }
      .article-content {
        margin-top: 40px;
        word-wrap: break-word;
        ::v-deep p {
          line-height: 32px;
          margin: 20px 0;
        }
        ::v-deep span {
          line-height: 28px;
        }
      }
    }
    .activity-right {
      width: 290px;
      flex: 0 0 290px;
    }
  }
}

.noart-tip {
  text-align: center;
  img {
    padding-left: 10px;
    margin-bottom: 6px;
  }
}
</style>
