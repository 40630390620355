/*
 * @Author: 蒋威
 * @Date: 2022-04-07 15:52:50
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-08-05 08:32:20
 * @Description:
 */
import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/iconfonts/iconfont.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/index.scss'
import './permission' // permission control
import BaiduMap from 'vue-baidu-map'
import VueLazyload from 'vue-lazyload'
import { getDate,getHtml } from '@/utils/index.js'
import Banner from '@/components/Banner.vue'
import Page from '@/components/Page.vue'
import NavBox from '@/components/NavBox.vue'
Vue.component('Banner', Banner)
Vue.component('Page', Page)
Vue.component('NavBox', NavBox)
Vue.use(ElementUI)
Vue.use(BaiduMap, {
  ak: 'yddQUPFfEQjqAowFN4uBRVnNWWVbbvPD',
})
// 统一请求状态码
Vue.prototype.$httpCode = 200
// 获取模型类型
Vue.prototype.$getDate = getDate
Vue.prototype.$getHtml = getHtml
Vue.config.productionTip = false
Vue.use(VueLazyload, {
  preLoad: 1,
  error: require('./assets/img/error.jpg'),
  loading: require('./assets/img/loading.gif'),
  attempt: 2,
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
