/*
 * @Author: 蒋威
 * @Date: 2022-04-07 16:51:49
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-09 16:05:09
 * @Description:
 */
import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { IEVersion } from '@/utils/index.js'
function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle}`
  }
  return `太阳慈善助学基金`
}
export const loadView = (view) => {
  // 路由懒加载
  return (resolve) => require([`@/views/${view}`], resolve)
}
// 是否是低版本浏览器
let lowVersion = [6, 7, 8, 9].includes(IEVersion())
console.log(lowVersion, 'lowVersion')
// 如果不是ie9 及以下版本浏览器
if (!lowVersion) {
  router.beforeEach(async (to, from, next) => {
    NProgress.start()
    //  load样式： 给中间内容一个固定高度，反正页面出现闪烁
    if (document.getElementsByClassName('app-main')[0]) {
      document.getElementsByClassName('app-main')[0].classList.add('load')
    }
    // 设置标题
    document.title = getPageTitle(to.meta.title)
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    next()
  })
  router.afterEach(() => {
    NProgress.done()
    if (document.getElementsByClassName('app-main')[0]) {
      document.getElementsByClassName('app-main')[0].classList.remove('load')
    }
    document.documentElement.scrollTop = 0
  })
}
