<!--
 * @Author: 蒋威
 * @Date: 2022-05-08 15:00:52
 * @LastEditors: chenpeng
 * @LastEditTime: 2022-05-19 16:19:18
 * @Description: 推荐活动
-->
<template>
  <div class="recommend-wrap" v-if="list.length > 0">
    <div class="top-wrap pointer">
      <h2 class="top-title pointer" @click="toActivity">推荐活动</h2>
      <i class="pointer iconfont icon-jiantou" @click="toActivity"></i>
    </div>
    <div class="conetnt-wrap">
      <div
        class="conetnt-item pointer"
        v-for="(item, index) in list"
        :key="index"
        @click="toActivity(item)"
      >
        <div class="conetnt-item-left">
          <img v-lazy="item.url" alt="" />
        </div>
        <div class="conetnt-item-right">
          <h4 class="conetnt-item-text ellipsis-2">{{ item.name }}</h4>
          <div class="conetnt-item-number">
            已报名人数：{{ item.activity_apply_num }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { channelTree } from '@/api/menu/index.js'
export default {
  inject: ['reload'],
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    menuList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      newList: [
        {
          id: 15,
          date: '2022-02-01 16:00:21',
          img: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          title: '反哺赋能课 【提升 情商】',
          number: 36,
        },
      ],
    }
  },
  methods: {
    toActivity(item) {
      if (this.menuList.length > 0) {
        this.getModuleId()
        let query = {}

        if (item && item.id) {
          query = { moduleId: this.moduleId || 53, id: item.id }
        } else {
          query = { moduleId: this.moduleId || 53 }
        }
        this.reload()
        this.$router.push({
          name: 'activity',
          query: query,
        })
      } else {
        this.getMenuList().then((res) => {
          let query = {}
          if (item && item.id) {
            query = { moduleId: this.moduleId || 53, id: item.id }
          } else {
            query = { moduleId: this.moduleId || 53 }
          }
          this.reload()
          this.$router.push({
            name: 'activity',
            query: query,
          })
        })
      }
    },
    getModuleId() {
      for (let index = 0; index < this.menuList.length; index++) {
        if (
          this.menuList[index].model == 'activity' &&
          this.menuList[index].title.indexOf('活动') != -1
        ) {
          this.moduleId = this.menuList[index].id
          break
        }
      }
      if (this.moduleId === null) {
        for (let index = 0; index < this.menuList.length; index++) {
          if (this.menuList[index].model == 'activity') {
            this.moduleId = this.menuList[index].id
            break
          }
        }
      }
    },
    async getMenuList() {
      const res = await channelTree({ diff: 'web' })
      if (res.code === this.$httpCode) {
        for (let index = 0; index < res.data.length; index++) {
          if (
            res.data[index].model == 'activity' &&
            res.data[index].title.indexOf('活动') != -1
          ) {
            this.moduleId = res.data[index].id
            break
          }
        }
        if (this.moduleId === null) {
          for (let index = 0; index < res.data.length; index++) {
            if (res.data[index].model == 'activity') {
              this.moduleId = res.data[index].id
              break
            }
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.recommend-wrap {
  width: 290px;
  .top-wrap {
    display: flex;
    height: 26px;
    position: relative;
    padding-left: 15px;
    align-items: center;
    justify-content: space-between;
    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      height: 26px;
      width: 4px;
      background: #e3a63b;
    }
    i {
      font-size: 18px;
      color: #cecfce;
    }
  }
  .conetnt-wrap {
    padding: 10px 0;
    .conetnt-item {
      display: flex;
      margin-top: 20px;
      height: 98px;
      .conetnt-item-left {
        width: 152px;
        flex: 0 0 152px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .conetnt-item-right {
        padding: 5px 10px;
        h4 {
          height: 36px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5b5a5a;
          line-height: 16px;
        }
        .conetnt-item-number {
          font-size: 14px;
          margin-top: 20px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #a09c9c;
          line-height: 16px;
        }
      }
    }
  }
}
</style>