/*
 * @Author: 蒋威
 * @Date: 2022-04-16 10:54:42
 * @LastEditors: chenpeng
 * @LastEditTime: 2022-05-19 15:07:49
 * @Description: 新闻相关
 */
import request from '@/utils/request'
// 菜单
export function queryByChannelId(params) {
  return request({
    url: `/api/web/queryByChannelId`,
    method: 'get',
    params,
  })
}
// 二级菜单
export function channelById(params) {
  return request({
    url: `/api/web/channelById`,
    method: 'get',
    params,
  })
}
// 新闻详情
export function detailsByNewsId(params) {
  return request({
    url: `/api/web/detailsByNewsId`,
    method: 'get',
    params,
  })
}
// 全部新闻
export function getNewsList(params) {
  return request({
    url: `api/news/list`,
    method: 'get',
    params,
  })
}
