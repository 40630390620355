<!--
 * @Author: 蒋威
 * @Date: 2022-05-06 14:54:58
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-14 08:53:03
 * @Description: 
-->
<template>
  <header class="layout-header">
    <div class="logo-wrap response">
      <img v-lazy="website.logo_id" alt="website.title" />
      <!-- <img src="~@/assets/img/logo.png" alt="太阳慈善基金会" /> -->
      <!-- this.$route.name -->
      <!-- ($route.query.moduleId || (menuList[0].id && !$route.query.id)) ==
              item.id
                ? 'active'
                : '' -->
      <span>您好，欢迎参与太阳慈善助学</span>
    </div>
    <!-- 一级菜单 -->
    <nav id="menuBox">
      <div class="first-menu-wrap">
        <ul class="first-menu response">
          <li
            class="first-menu-item pointer"
            :class="
              $route.name == 'home' && item.model == 'home'
                ? 'active'
                : $route.name != 'home' && $route.query.moduleId == item.id
                ? 'active'
                : ''
            "
            v-for="(item, index) in menuList"
            :key="index"
            @click="changeFirstMenu(item, index)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import { channelTree } from '@/api/menu/index.js'
import { website_list } from '@/api/homePage/index.js'
export default {
  name: 'LayoutHeader',
  inject: ['reload'],
  computed: {},
  data() {
    return {
      //网站配置
      website: {},
      menuList: [
        // {
        //   name: '首页',
        //   model: 'home',
        //   id: 0,
        //   children: [],
        // },
        // {
        //   name: '新闻动态',
        //   model: 'news',
        //   id: 1,
        // },
        // {
        //   name: '公益活动',
        //   model: 'activity',
        //   id: 2,
        // },
        // {
        //   name: '公益项目',
        //   model: 'project',
        //   id: 3,
        // },
        // {
        //   name: '关于我们',
        //   model: 'about',
        //   id: 4,
        // },
        // {
        //   name: '信息公示',
        //   model: 'publicity',
        //   id: 5,
        // },
        // {
        //   name: '党建动态',
        //   model: 'partyBuilding',
        //   id: 6,
        // },
      ],
    }
  },
  methods: {
    // 获取网站配置信息
    async getwebsite_list() {
      const res = await website_list()
      if (res.code === this.$httpCode) {
        this.website = res.data
        console.log(this.website, 'sss')
      }
    },
    /**
     * @Auth: 蒋威
     * @Date: 2022-05-08 14:44:12
     * @Description: 获取路由名称
     * @param {*} model
     */
    getRouterName(model, shorts) {
      let name = 'home'
      switch (model) {
        case 'home':
          name = 'home'
          break
        case 'news':
          if (shorts) {
            shorts == 'publicity'
            name = 'publicity'
          } else {
            name = 'news'
          }
          break
        case 'activity':
          name = 'activity'
          break
        case 'project':
          name = 'project'
          break
        case 'about':
          name = 'about'
          break
        case 'publicity':
          name = 'publicity'
          break
        case 'partyBuilding':
          name = 'partyBuilding'
          break
        default:
          name = 'home'
          break
      }
      return name
    },
    changeFirstMenu(item, index) {
      if (item.model == 'url' && item.url) {
        window.open(item.url, '_blank')
        return
      }
      let query
      if (index == 0) {
        query = {}
      } else {
        query = {
          moduleId: item.id,
        }
      }
      this.reload()
      console.log(item, 'item')
      this.$router.push({
        name: this.getRouterName(item.model, item.shorts),
        query: query,
      })
    },
    async getMenuList() {
      const res = await channelTree({ diff: 'web' })
      if (res.code === this.$httpCode) {
        this.menuList = res.data
      } else {
        this.$emit('setType', '404')
      }
      if (this.menuList.length < 1) {
        this.$emit('setType', '404')
      }
    },
  },
  created() {
    this.getMenuList()
    this.getwebsite_list()
    console.log(this.$route, 555)
  },
}
</script>
<style lang="scss" scoped>
header {
  width: 100%;
  .logo-wrap {
    height: 118px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 337px;
      height: 81px;
    }
    span {
      height: 26px;
      font-size: 20px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #a09c9c;
      line-height: 23px;
    }
  }
  nav {
    // 一级菜单
    .first-menu-wrap {
      background: #e3a63b;
      height: 80px;
      width: 100%;
    }
    .first-menu {
      display: flex;
      height: 80px;
      align-items: center;
      justify-content: space-between;
      .first-menu-item {
        // flex: 0 0 120px;
        width: 140px;
        padding: 0 10px;
        height: 80px;
        line-height: 80px;
        // display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-box-orient: vertical;
        text-align: center;
        &:hover {
          background: #f0b54d;
          display: flex;
          width: auto;
          min-width: 140px;
          max-width: 220px;
        }
        &.active {
          background: #f0b54d;
        }
      }
    }
  }
}
#menuBox {
  width: 100%;
}
#menuBox.fixedBox {
  position: fixed;
  z-index: 12;
  top: 0;
  width: 100%;
}
</style>
