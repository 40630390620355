<!--
 * @Author: 蒋威
 * @Date: 2022-05-09 10:20:36
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-14 08:53:06
 * @Description: 二级菜单
-->
<template>
  <ul class="second-menu response">
    <li
      class="second-menu-item pointer"
      v-for="(item, index) in menuList"
      :key="index"
      :class="{
        active:
          !!$route.query.id || $route.query.id == 0
            ? $route.query.id - 0 == item.id
            : item.id == menuList[0].id - 0,
      }"
      @click="changeMenu(item, index)"
    >
      <span>
        {{ item.title }}
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  inject: ['reload'],
  props: {
    menuList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      // 选中的索引
      activeIndex: 0,
    }
  },
  methods: {
    changeMenu(item, index) {
      this.activeIndex = index
      if (item.model == 'url' && item.url) {
        window.open(item.url, '_blank')
      } else {
        this.$emit('changeMenu', item)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.second-menu {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  .second-menu-item {
    flex: 0 0 140px;
    line-height: 20px;
    height: 88px;
    padding-top: 40px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    margin-right: 10px;
    span {
      border-radius: 10px 10px 0px 0px;
      height: 48px;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
    &:hover,
    &.active {
      span {
        color: #ffffff;
        background: #e3a63b;
      }
    }
  }
}
</style>