<!--
 * @Description: 头部banner
 * @Author: chenpeng
 * @Date: 2022-04-08 09:22:16
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-10 10:18:09
 * @FilePath: \souhu\src\components\banner.vue
-->
<template>
  <div class="banner" v-if="bannerList[0] && bannerList[0].file_url">
    <img
      class="banner-img"
      :src="bannerList[0].file_url"
      alt=""
      @click="gotojump(bannerList[0])"
      :class="bannerList[0].url ? 'pointer' : ''"
    />
  </div>
</template>

<script>
export default {
  name: 'banner',
  props: {
    bannerList: {
      type: Array,
      default: () => {
        return []
      },
    },
    zhTitle: {
      type: String,
      default: '',
    },
    enTitle: {
      type: String,
      default: '',
    },
    bgImg: {
      type: String,
      default: '',
      // default: require('@/assets/img/banner/Frame 3.png'),
    },
    // 跳转地址
    url: {
      type: String,
    },
  },
  methods: {
    /**
     * @Auth: 蒋威
     * @Date: 2022-04-19 16:44:18
     * @Description: 点击跳转
     */
    gotojump(item) {
      if (item.url) {
        window.open(item.url, '_blank')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 29vw;
  background: #fff;
  .banner-img {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .banner {
  }
}

@media only screen and (max-width: 767px) {
  .banner {
  }
}

@media only screen and (max-width: 500px) {
  .banner {
  }
}
</style>